import { Header } from './Header.js'

export function Confused() {
    return (
      <>
      <Header/>
      <iframe title="bubbles" src="https://giphy.com/embed/1XoXJkOGri6Hu" frameBorder="0" className="giphy-embed w-full mt-4"></iframe>
      <div className="text-center">
       <a href="https://giphy.com/gifs/1XoXJkOGri6Hu">via GIPHY</a>
      </div>
     </>
    )   
}
