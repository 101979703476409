import React, {useState} from "react"
import { useNavigate } from "react-router-dom"
import {  Navigate } from "react-router-dom";

import { useAuth } from "./AuthProvider.js"
import { Header } from './Header.js'


export function Logout() {
    const navigate = useNavigate()
    const { loggedIn, logout } = useAuth()
    const [ loggingOut, setLoggingOut ] = useState(false)

    async function onSubmit(e) {
        e.preventDefault()

        setLoggingOut(true)
        logout().finally(meh => {
            setLoggingOut(false);
            navigate("/")
         });
    }

    if (!loggedIn) {
        return <Navigate to="/"/>
    }

    return (
        <>
        <Header/>
        <div className="content w-full md:w-1/2 md:translate-x-1/2">
            <div className="text-base pt-4 px-1 text-black select-none">
                <div className="text-xl px-4">
                    <form id="logout-form" onSubmit={onSubmit}>
                        <div className="form-group mt-4">
                            <button
                                disabled={loggingOut} 
                                form_id="logoutform" type="submit" value="Sign Out" 
                                className="bg-blue-700 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded-full border-none w-full">
                                    Sign Out
                            </button>
                        </div>
                    </form>  
                </div>      
            </div>
        </div> 
        </>         
    )


}