import React, { useRef, useState } from "react"
import { useAuth } from "./AuthProvider"
import {  Navigate } from "react-router-dom";

import { Header } from './Header.js'

function Alert(props) {
    return (
        <div className="bg-red-500 text-white text-2xl w-full p-2 mb-4 text-center">{props.string}</div>
    )
}

export function Login() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const { login, loggedIn, lastLoginError } = useAuth()
    const [ loggingIn, setLoggingIn] = useState(false)

    async function onSubmit(e) {
        e.preventDefault()

        setLoggingIn(true)
        
        login(emailRef.current.value, passwordRef.current.value)
        .finally(meh => {
            setLoggingIn(false)
        });
    }

    if (loggedIn) {
        return <Navigate to="/"/>
    }

    return (
        <>
        <Header/>
        <div className="content w-full md:w-1/2 md:translate-x-1/2">
            <div className="text-base pt-4 px-1 text-black select-none">
                <div className="text-xl px-4">
                    {lastLoginError && !loggingIn && <Alert string={lastLoginError}/>}
                    <form id="login-form" onSubmit={onSubmit}>
                        <div className="form-group">
                            <input type="text" placeholder="user" name="username" id="username" ref={emailRef} 
                            className="w-full px-4 py-2 bg-blue-100 border-blue-500"/>
                        </div>
                        <div className="form-group mt-2">
                            <input 
                                type="password" placeholder="password" name="password" id="password" ref={passwordRef} 
                                className="w-full px-4 py-2 bg-blue-100 border-blue-500"/>
                        </div>
                        <div className="form-group mt-4">
                            <button
                                disabled={loggingIn} 
                                form_id="login-form" type="submit" value="Sign in" 
                                className="bg-blue-700 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded-full border-none w-full">
                                    Sign In
                            </button>
                        </div>
                    </form>  
                </div>      
            </div>
        </div> 
        </>         
)
}


