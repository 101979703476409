import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute.js'
import { AuthProvider } from './AuthProvider';

import { Login } from './Login.js'
import { Logout } from './Logout.js'
import { Content } from './Content.js'
import { Confused } from './Confused.js'

function App() {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
            <Routes>
              <Route exact path="/" element={<PrivateRoute><Content/></PrivateRoute>}/>
              <Route path="/login" element={<Login/>}/>
              <Route path="/logout" element={<Logout/>}/>
              <Route path="*" element={<Confused/>}/>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
