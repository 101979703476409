import React, { useContext, useState, useEffect } from "react"
import axios from 'axios'

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [loading, setLoading] = useState(true)
  const [loggedIn, setLoggedIn] = useState(false)
  const [lastLoginError, setLastLoginError] = useState("")

  function login(email, password) {
    return axios.post('/api/login', {
      username: email,
      password: password
    })
    .then(response => {
      let authorized = response.data.authorized ? true : false
      setLastLoginError(authorized ? "" : "Unable to sign in")
      setLoggedIn(authorized)
    })
    .catch(error => {
      console.log("Login error:", error);
      setLastLoginError("Server Error")
      setLoggedIn(false)
    })
  }

  function logout() {
    return axios.post('/api/logout', {})
    .then(response => {
      setLoggedIn(false)
    })
    .catch(error => {
      console.log("Logout error:", error);
    });
  }

  useEffect(() => { 
    // This needs to move to a class that handles logging in once and notifies
    // listeners.
    if (!loggedIn) {
      axios.get('/api/login')
      .then(response => {
        let authorized = response.data.authorized
        setLoggedIn(authorized)
      })
      .catch(error => {
        console.log("Server error checking loggedIn")
      })
      .finally(meh => {
        setLoading(false)
      })
    }
  }, [loggedIn, loading])

  const value = {
    loggedIn,
    lastLoginError,
    loading,
    login,
    logout,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}