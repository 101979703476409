import { useEffect, useState } from 'react';
import axios from 'axios';

//import { Header } from './Header.js'

function Card(props) {
    return <div className="bg-blue-100 text-black m-2 p-2 md:mt-4 md:mb-4 rounded-lg">
       <div className="text-xl md:text-3xl mb-2">{props.title}</div>
       <div className="pl-2 pr-2">
          {props.children}
        </div>
    </div>
  }
  
function Slide(props) {
    const images = props.slide.images.map((image, index) =>
        <img className="mb-2" key={image} src={image} alt={props.slide.title}/>
    )

    return <Card title={props.slide.title}>
        <div className="text-sm md:text-2xl mb-2 mt-2">{props.slide.description}</div>
        {images}
    </Card>
}
  
function Map(props) {
    return <Card title="Location">
        <iframe className="w-full" title="map" src={props.uri} width="640" height="480" allowFullScreen="" loading="lazy"/>
    </Card>
}

function AllCards(props) {
    const allSlides = props.manifest.slides.map((slide, index) =>
        <Slide key={slide.title} slide={slide}/>
    )

    return (
        <>
        {allSlides}
        <Map uri={props.manifest.map}/>
        </>
    )
}
  
export function Content() {
    const [loading, setLoading] = useState(true)
    const [manifest, setManifest] = useState('')
  
    useEffect(() => {
      axios.get('/api/data/manifest.json')
      .then((response) => {
        setManifest(response.data)
        setLoading(false)
      })
      .catch(error => console.log(error))
    }, []);
  
    return (
      <div className="bg-blue-50">
        <div className="w-full md:w-2/3 md:translate-x-1/3 lg:w-1/2 lg:translate-x-1/2">
          {!loading && <AllCards manifest={manifest}/>}
        </div>
      </div>
    )
  }


